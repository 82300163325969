import React from 'react';

import { Layout, Seo } from 'components';

const NotFoundPage: React.FC = props => (
  <Layout>
    <Seo />
    <h1>404 Not Found</h1>
  </Layout>
)

export default NotFoundPage;